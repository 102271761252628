import { ofType, combineEpics, } from 'redux-observable';
import {
  map, pluck, filter, flatMap, tap, ignoreElements, take, switchMap,
} from 'rxjs/operators';
import { actions, socket$, } from '@ezugi/bootstrap';
import {
  INITIAL_DATA, PLACE_YOUR_BETS, CARD_MESSAGE, WON_BETS, GAME_RESULT, CARD,
} from '@ezugi/constants';
import { Card, } from '@ezugi/primitives';


import handleInitialData from './handleInitialData';
import handlePlaceYourBets from './handlePlaceYourBets';
import handleWonBets from './handleWonBets';
import handleGameResult from './handleGameResult';
import handleCardMessage from './handleCardMessage';

const {
  socketActions: { socket, },
  videoActions: { video, },
} = actions;

function initialDataEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap(handleInitialData)
  );
}

function wonBetsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === WON_BETS),
    map(handleWonBets)
  );
}

function gameResultEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap((socketMessage) => handleGameResult(socketMessage, state$.value))
  );
}


function placeYourBetsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === PLACE_YOUR_BETS),
    flatMap(handlePlaceYourBets)
  );
}


function cardMessageEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CARD),
    flatMap((socketMessage) => handleCardMessage(socketMessage, state$.value))
  );
}

function preloadCardEpic(action$) {
  return action$.pipe(
    ofType(video.set),
    take(1),
    pluck('payload', 'cdnList', 'videoDelay'),
    switchMap((videoDelay) => (+videoDelay
      ? socket$.pipe(
        filter(({ MessageType, }) => MessageType === CARD_MESSAGE),
        pluck('CardName'),
        tap(Card.preload)
      )
      : socket$.pipe(
        take(1),
        tap(() => {
          Card.preloadAll();
        })
      ))),
    ignoreElements()
  );
}

export default combineEpics(
  initialDataEpic,
  cardMessageEpic,
  wonBetsEpic,
  gameResultEpic,
  placeYourBetsEpic,
  preloadCardEpic,
);
