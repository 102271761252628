import { of, } from 'rxjs';

import { actions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';


const {
  roundActions: { round, },
  gameActions: { game, },
} = actions;


export default function handleGameResult(socketMessage) {
  const {
    roundId,
    timeStamp,
    WinAmount,
    LastWinners,
    GameResults: { WinningBets, } = {},
  } = socketMessage;

  return of(
    round.set({
      roundStatus: GAME_RESULT,
      roundId,
      winAmount: WinAmount,
      winnerList: LastWinners,
      winningBets: WinningBets,
      timestamp: timeStamp,
    }),
    game.set({
      lastWin: WinAmount,
    })
  );
}
