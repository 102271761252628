export const PAYOUT_GROUPS = {
  GENERAL: '*',
  MAIN: 'Main',
  SIDEBETS: 'SideBets',
  COLOUR: 'Colour',
  ODD_EVEN: 'OddEven',
};

export const NO_WELCOME_DIALOG = 'NO_WELCOME_DIALOG_L7';
export const WELCOME_DIALOG = 'WelcomeDialog';
export const APPROVED = 'APPROVED';
