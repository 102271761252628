import { actions, } from '@ezugi/bootstrap';
import { pick, } from 'ramda';

import { SIDEBETS, MAIN_BETS, } from '../../../constants/betTypes';

const {
  roundActions: { round, },
} = actions;

const EMPTY_OBJ = {};

export default function handleGameResult({ WinningBets = EMPTY_OBJ, }) {
  return round.set({
    wonBets: pick(Object.values(SIDEBETS))(WinningBets),
    wonMainBets: pick(Object.values(MAIN_BETS))(WinningBets),
    wonSideBets: pick(Object.values(SIDEBETS))(WinningBets),
  });
}
