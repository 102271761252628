import { of, } from 'rxjs';
import { LOADING, } from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';
import { ls, } from '@ezugi/utils';

import { APPROVED, NO_WELCOME_DIALOG, WELCOME_DIALOG, } from '../../../constants/strings';


const {
  dialogActions: { dialog, },
  socketActions: { socket, },
  videoActions: { video, },
  roundActions: { round, },
  settingsActions: { settings, },
  betActions: { bet, history, },
} = actions;

const [ err, noWelcomeDialog, ] = ls.get(NO_WELCOME_DIALOG);

export default function handleInitialData(socketMessage) {
  const {
    VideoStreamData,
    roundId,
    timeStamp,
    RoundTripStartTime,
    regularBets = [],
    sideBets = [],
  } = socketMessage;
  return of(
    ...[
      socket.success(socketMessage),
      settings.init(),
      video.set({ streamData: VideoStreamData, }),
      round.set({
        roundStatus: LOADING,
        roundId,
        roundTime: RoundTripStartTime,
        timestamp: timeStamp,
      }),
      bet.apply({
        current: regularBets.concat(sideBets).reduce((acc, b) => {
          (b.approved || b.status === APPROVED)
           && !b.cancelled
           && (acc[b.type] = { value: b.betAmount, valid: true, });
          return acc;
        }, {}),
      }),
      // reset history to prevent sending bets to server again
      // because NO_MORE_BETS message comes after this actions
      history.reset(),
      ...(err || !noWelcomeDialog ? [ dialog.add({ name: WELCOME_DIALOG, }), ] : []),
    ]
  );
}
