import { PAYOUT_GROUPS as GROUPS, } from '../../../constants/strings';
import * as BET_TYPES from '../../../constants/betTypes';

const payoutGroupsMap = {
  0: { group: GROUPS.MAIN, label: BET_TYPES.SEVEN_UP, },
  1: { group: GROUPS.MAIN, label: BET_TYPES.SEVEN, },
  2: { group: GROUPS.MAIN, label: BET_TYPES.SEVEN_DOWN, },
  3: { group: GROUPS.COLOUR, label: BET_TYPES.RED, },
  4: { group: GROUPS.COLOUR, label: BET_TYPES.BLACK, },
  5: { group: GROUPS.ODD_EVEN, label: BET_TYPES.ODD, },
  6: { group: GROUPS.ODD_EVEN, label: BET_TYPES.EVEN, },
};

export function formatPayouts(list = []) {
  return list.reduce(
    (acc, p) => {
      const { option1: payout, } = JSON.parse(p.payout);
      const { group = GROUPS.MAIN, label, } = payoutGroupsMap[p.IndexID];
      const _p = { ...p, label, payout, };
      acc[group].push({ ...p, label, payout, });
      acc[GROUPS.GENERAL][label] = _p;
      return acc;
    },
    {
      [GROUPS.MAIN]: [],
      [GROUPS.COLOUR]: [],
      [GROUPS.ODD_EVEN]: [],
      [GROUPS.GENERAL]: {},
    }
  );
}
