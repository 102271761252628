import { LUCKY_7_BET_TYPES, } from '@ezugi/constants';

export const {
  // newline
  SEVEN_UP,
  SEVEN,
  SEVEN_DOWN,
  RED,
  BLACK,
  ODD,
  EVEN,
} = LUCKY_7_BET_TYPES;

export const SIDEBETS = {
  RED,
  BLACK,
  ODD,
  EVEN,
};

export const MAIN_BETS = {
  SEVEN_UP,
  SEVEN,
  SEVEN_DOWN,
};

export default { ...LUCKY_7_BET_TYPES, };
