import { of, concat, } from 'rxjs';

import { SEVEN_UP, SEVEN, SEVEN_DOWN, } from '../../../constants/betTypes';
import cardsActions from '../../actions/cards';

function getWinningBetType(cardValue) {
  return cardValue > 7 ? SEVEN_UP : cardValue < 7 ? SEVEN_DOWN : SEVEN;
}

export default function handleCardMessage(socketMessage) {
  const { CardHand, CardName, CardValue, } = socketMessage;
  const card = { CardName, CardValue, };

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: card,
        [getWinningBetType(CardValue)]: card,
      })
    )
  );
}
