import {
  socketEpic,
  payoutsEpic,
  betsEpic,
  betsReducer,
  cardsEpic,
  cardsReducer,
  statisticsReducer,
  statisticsEpic,
} from './store';
import * as styles from './components/ResultMessage/styles';

export default () => ({
  components: {
    Payouts: () => import('./components/Payouts'),
    GameBoard: () => import('./views/GameBoard'),
    GameInfo: () => import('./components/GameInfo'),
    VideoOverlay: () => Promise.resolve({ default: () => null, }),
    ProgressBar: () => Promise.resolve({ default: () => null, }),
    ResultDisplay: () => import('./components/ResultDisplay'),
    SidebetsWinMessage: () => import('./components/SidebetsWinMessage'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    SideBetsInfoDialog: () => import('./components/SidebetsInfoDialog'),
    Statistics: () => Promise.resolve({ default: () => null, }),
  },
  props: {
    ResultMessage: { className: styles.resultMessage, },
    BetToolbar: {
      style: {
        zIndex: 1,
      },
    },
  },
  store: {
    epics: [ socketEpic, payoutsEpic, betsEpic, cardsEpic, statisticsEpic, ],
    reducers: { bets: betsReducer, cards: cardsReducer, statistics: statisticsReducer, },
  },
  gameBoardHeight: 22,
  desktopBoardToggle: true,
  landscapeToolbarOrientation: 'vertical',
  hideLeftSideDrawer: true,
});
